import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Dropzone from "../common/CustomDropzone";
import FlatPickr from "react-flatpickr";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

import moment from 'moment-timezone';
import runtimeEnv from "@mars/heroku-js-runtime-env";
import JoditEditor from "jodit-react";

const env = runtimeEnv();
const INVESTOR_URL = process.env.REACT_APP_INVESTOR_POST_URL;

export default class PressReleaseModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSetOnHomePage: false,
      isReportActive: false,
      isReportFeatured: false,
      data: props.data || { published_at: new Date() },
      type: props.type,
      showResultType: props.type ? true : false,
      show: props.show,
      mode: props.mode,
      imageId: props.data?.image?.id || null,
      documentUrl: props.data?.file || null,
      homePageSliderCount: 0,
      selectedSlide: props.data ? props.data.initial_slide_number : ""
    };
    this._handleChange = this._handleChange.bind(this);
    this._handleClickToCopy = this._handleClickToCopy.bind(this); 
    this.editor = null;
    this.config = {
      readonly: false,
      placeholder: this.props.placeholder || "Report content",
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      height: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      let data = { ...this.state.data };
      data["old_slug"] = this.props.data?.slug;
      if (this.props.data?.initial_slide_number >= 1) {
        this._getHomePageSliderCount(this.props.data?.initial_slide_number);
      }
      this.setState({ data });
      this.setState({
        isSetOnHomePage: this.props.data?.add_to_homepage_slider || false,
        isReportActive: this.props.data?.is_live || false,
        isReportFeatured: this.props.data?.featured || false,
        imageId: this.props.data?.image_id?.id || null,
        documentUrl: this.props.data?.file_url || null,
        setToHomePageInitialState:
          this.props.data?.add_to_homepage_slider || false,
          selectedSlide:this.props.data?.initial_slide_number || "",
      });
    }
  }

  _getTimezone() {
    const date = moment();
    const gmtString = date.format('Z');
    const timezoneName = date.tz(moment.tz.guess()).format('zz');
    
    return `GMT${gmtString} (${timezoneName})`;
  }

  _updateData(key, value) {
    let data = { ...this.state.data };
    data[key] = value;
    this.setState({ data });
  }

  _isFormValid() {
    let { data, isSetOnHomePage, mode, isReportFeatured, imageId,selectedSlide } = this.state;

    let error = null;

    if (!data.featured_report_image && mode == "add") {
      error = "Featured image is required. Please upload";
    } else if (!data.title) {
      error = "Report title is required.";
    } else if (!data.slug) {
      error = "Report url is required.";
    } else if (!data.report_document_url && mode == "add") {
      error = "Report document is required. Please upload";
    } else if (!data.body) {
      error = "Report content is required.";
    } else if (isSetOnHomePage) {
      if (!selectedSlide) {
        error = "Slide number is required if report is added to home page";
      }
    }

    if (
      mode === "edit" &&
      (isSetOnHomePage || isReportFeatured) &&
      !imageId &&
      !data.featured_report_image
    ) {
      error = "Featured image is required.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _updateResult() {
    let { data, mode, imageId, documentUrl, selectedSlide } = this.state;

    if (!this._isFormValid()) {
      return;
    }
    data.is_live = this.state.isReportActive;
    data.featured = this.state.isReportFeatured;
    data.add_to_homepage_slider = this.state.isSetOnHomePage;
    data.initial_slide_number =
    selectedSlide != null &&
    selectedSlide != undefined &&
      this.state.isSetOnHomePage
        ? selectedSlide
        : null;
    data.published_at =
      data.published_at != null && data.published_at != undefined
        ? data.published_at
        : "";

    if (data.featured_report_image && (mode === "add" || mode === "edit")) {
      data.image = { id: data.featured_report_image };
    }
    if (data.report_document_url && (mode === "add" || mode === "edit")) {
      data.file_url = data.report_document_url;
    }
    if (
      mode === "edit" &&
      (data.featured_report_image === undefined ||
        data.featured_report_image === "") &&
      imageId != null
    ) {
      data.image = { id: imageId };
    }
    if (
      mode === "edit" &&
      (data.report_document_url === undefined ||
        data.report_document_url === "") &&
      documentUrl != null
    ) {
      data.file_url = documentUrl;
    }
    this.setState({ isLoading: true });
    Backend.submitOrUpdateReport(data, mode)
      .then(() => {
        this.setState({
          isLoading: false,
        });
        Notify.success("Report has been added successfully.");
        this.setState({
          isSetOnHomePage: false,
          isReportActive: false,
          isReportFeatured: false,
          data: {},
        });
        this.props.onUpdated();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _getHomePageSliderCount(slideNumber = null) {
    this.setState({ isLoading: true });
    let mode = this.state.mode;
    Backend.getHomePageSliderNumber()
      .then((response) => {
        let imageCount = 0;
        if (response?.total_slider_count) {
          imageCount = response?.total_slider_count;
          if (mode == "add") {
            imageCount = imageCount + 1;
          } else if (
            mode == "edit" &&
            this.state.setToHomePageInitialState == false
          ) {
            imageCount = imageCount + 1;
          }
        } else {
          imageCount = 1;
        }
        // Update state with the count
        this.setState({
          isLoading: false,
          homePageSliderCount: imageCount,
        });
        if(slideNumber==null){
          slideNumber = 1;
        }
        this.setState({ selectedSlide: slideNumber});
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _updateIsSetOnHomePage() {
    this.setState(
      (prevState) => ({
        isSetOnHomePage: !prevState.isSetOnHomePage,
      }),
      () => {
        if (this.state.isSetOnHomePage) {
          this._getHomePageSliderCount();
        }
      }
    );
  }

  _updateIsReportActive() {
    this.setState((prevState) => ({
      isReportActive: !prevState.isReportActive,
    }));
  }

  _updateIsReportFeatured() {
    this.setState((prevState) => ({
      isReportFeatured: !prevState.isReportFeatured,
    }));
  }

  _handleChange(event){
    this.setState({ selectedSlide: event.target.value });
  }

  _handleClickToCopy(){
    let { data } = this.state;
    let url = `${INVESTOR_URL}/${data?.slug ? data.slug : ""}`;
    navigator.clipboard.writeText(url).then(() => {
      Notify.success("URL copied to clipboard!");
    }).catch(err => {
      Notify.success("Failed to copy");
    });
  }

  render() {
    const { data, mode, show, isLoading, fileUploading } = this.state;
    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.setState({
              isSetOnHomePage: false,
              isReportActive: false,
              isReportFeatured: false,
              data: {},
            });
            this.props.onHide();
          }
        }}
        backdrop={this.props.backdrop ? this.props.backdrop : "false"}
      >
        <div className="modal-content w-600px">
          <Modal.Header closeButton>
            <Modal.Title>
              {mode === "edit" ? "Edit Report" : "Add New Report"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form id="edit-result-form" className="form create-post" action="#">
              <div className="d-flex flex-column mb-7 fv-row">
                <label className=" fs-6 fw-bold form-label mb-2">
                  Featured Image
                </label>
                <Dropzone
                  type="image"
                  subtitle="Upload a .JPG or .PNG"
                  multi={false}
                  onUpload={(file) => {
                    this.setState({ fileUploading: false });
                    this._updateData("featured_report_image", file.id);
                  }}
                  isUploading={(value) =>
                    this.setState({ fileUploading: value })
                  }
                  onRemove={() => {
                    this.setState({ fileUploading: false });
                    delete data.featured_report_image;
                  }}
                />
              </div>

              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="required">Post Title</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="What is the name of the post"
                  />
                </label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder=""
                  value={data?.title}
                  name="post_title"
                  onChange={(e) => {
                    this._updateData("title", e.target.value);
                  }}
                />
              </div>

              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="">Post Subtitle</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder=""
                  value={data?.subtitle}
                  name="subtitle"
                  onChange={(e) => {
                    this._updateData("subtitle", e.target.value);
                  }}
                />
              </div>
              <div className="d-flex flex-column mb-7 fv-row">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <label className="fs-6 fw-bold form-label">
                    <span className="required">Post URL</span>
                  </label>
                  <i
                    className="fas fa-copy copy-icon"
                    data-bs-toggle="tooltip"
                    title="Click to copy"
                    onClick={this._handleClickToCopy}
                  />
                </div>
                <div class="form-group m-form__group">
                  <div class="input-group m-input-group">
                    <div class="input-group-prepend">
                      <span class="form-control form-control-solid">
                        {INVESTOR_URL}/
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder=""
                      value={data?.slug}
                      name="slug"
                      onChange={(e) => {
                        this._updateData("slug", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row">
                <label className="fs-6 fw-bold form-label mb-2">
                  Post Date / Time &nbsp;<span className="text-muted small">{this._getTimezone()}</span>
                </label>
                <FlatPickr
                  value={
                    (data?.published_at || mode=="edit")
                      ? data?.published_at
                      : new Date()
                  }
                  className="form-control form-control-solid"
                  options={{
                    enableTime: true,
                    noCalendar: false,
                    altInput: false,
                    dateFormat: "Y-m-d H:i",
                    static: true,
                    allowInput: false,
                  }}
                  onChange={(dates) => {
                    let date = moment(dates[0]).format("YYYY-MM-DDTHH:mmZ");
                    this._updateData("published_at", date);
                  }}
                />
              </div>

              <div className="d-flex flex-column mb-7 fv-row">
                <label className="required fs-6 fw-bold form-label mb-2">
                  Upload PDF
                </label>
                <Dropzone
                  type="application/pdf, application/msword"
                  subtitle="Upload a .PDF or .DOC"
                  multi={false}
                  onUpload={(file) => {
                    this.setState({ fileUploading: false });
                    this._updateData("report_document_url", file.url);
                  }}
                  isUploading={(value) =>
                    this.setState({ fileUploading: value })
                  }
                  onRemove={() => {
                    this.setState({ fileUploading: false });
                    delete data.report_document_url;
                  }}
                />
              </div>
              <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="required">Post content</span>
                </label>
                <JoditEditor
                  ref={(ref) => (this.editor = ref)}
                  value={data?.body}
                  config={this.config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => this._updateData("body", newContent)}
                />
              </div>

              <div className="d-flex flex-column mb-7 fv-row">
                <div className="form-check form-switch form-check-custom form-check-solid">
                  <input
                    onClick={() => this._updateIsSetOnHomePage()}
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="is_post_on_home_page"
                    checked={this.state.isSetOnHomePage}
                  />
                  <label
                    className="form-check-label fw-bold ms-3"
                    for="is_post_on_home_page"
                  >
                    Add to home page slider
                  </label>
                </div>
              </div>
              {this.state.isSetOnHomePage && (
                <div className="d-flex flex-column mb-7 fv-row">
                  <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span className="">
                      Slide No. (This will push back all slides after it)
                    </span>
                  </label>
                  <select
                    className="form-control form-control-solid home-page-slider-number"
                    value={this.state.selectedSlide}
                    onChange={this._handleChange}
                  >
                    {Array.from(
                      { length: this.state.homePageSliderCount },
                      (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      )
                    )}
                  </select>
                </div>
              )}
              <div className="d-flex flex-column mb-7 fv-row">
                <div className="form-check form-switch form-check-custom form-check-solid">
                  <input
                    onClick={() => this._updateIsReportActive()}
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="is_post_active"
                    checked={this.state.isReportActive}
                  />
                  <label
                    className="form-check-label fw-bold ms-3"
                    for="is_post_active"
                  >
                    Make post active?
                  </label>
                </div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row">
                <div className="form-check form-switch form-check-custom form-check-solid">
                  <input
                    onClick={() => this._updateIsReportFeatured()}
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="is_featured_post"
                    checked={this.state.isReportFeatured}
                  />
                  <label
                    className="form-check-label fw-bold ms-3"
                    for="is_featured_post"
                  >
                    Set as feature post (This will remove current feature post)
                  </label>
                </div>
              </div>

              <div className="text-center pt-15">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    this.setState({
                      isSetOnHomePage: false,
                      isReportActive: false,
                      isReportFeatured: false,
                      data: {},
                    });
                    this.props.onHide();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  id="edit-success"
                  className="btn btn-primary"
                  disabled={isLoading || fileUploading}
                  data-bs-dismiss="modal"
                  onClick={() => this._updateResult()}
                >
                  {isLoading || fileUploading
                    ? "Please wait..."
                    : "Save Changes"}
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}
